import React from 'react';
import { graphql} from 'gatsby';
import MissingTranslation from '../../templates/multilanguage/MissingTranslation';

const ErrorPage = ({ data }) => {
  return <MissingTranslation data={data} />
}

export const query = graphql`
 query TranslationMissingPageRo {
  mdx(frontmatter: {type: {eq: "translation-missing"} language: {eq: "fr"}}) {
      id
      fields {
        slug
        lang
        canonical
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          fr {
            url
            country
          }
          es {
            url
            country
          }
          de {
            url
            country
          }
          pl {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        image {
          publicURL
        }
        buttonText
      }
  }
}
`

export default ErrorPage;
